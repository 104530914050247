@import 'styles/_variables.scss'; /* Assuming this holds your color variables like --text-tertiary */

/* --- Global Font Setting --- */
.ruling-panel,
.ruling-panel * { /* Apply Lato broadly first */
    font-family: 'Lato', sans-serif;
    box-sizing: border-box; /* Good practice */
}

/* --- Base Element Styles (Refined) --- */
.ruling-panel p {
    color: var(--text-tertiary);
    line-height: 1.5em; /* Slightly increased for readability */
    font-size: 14px;
    font-weight: 400; /* Standard weight for body text */
    margin-top: 0; /* Remove default top margin */
    margin-bottom: 1em; /* Consistent spacing below paragraphs */
}

/* Style for the main title coming from the backend HTML */
.ruling-panel h1 {
    color: var(--text-tertiary);
    font-size: 28px; /* Larger for main doc title */
    font-weight: 700;
    text-align: center; /* Often centered in rulings */
    margin-top: 0;
    margin-bottom: 1.5em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

/* Style for the H3 rendered by React (outside dangerouslySetInnerHTML) */
.ruling-panel h3 {
    margin-bottom: 15px; /* Increased bottom margin */
    line-height: 1.2;
    color: var(--text-tertiary);
    font-size: 20px; /* Slightly smaller than backend H1 */
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    border-bottom: 1px solid #484848; /* Add underline */
    padding-bottom: 5px; /* Spacing for underline */
}

/* Keep other heading styles if needed elsewhere, but they might not appear in this specific HTML */
.ruling-panel h2 {
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.1;
    color: var(--text-tertiary);
    border-color: #484848;
    border-style: solid;
    border-width: 0 0 1px 0;
    font-size: 24px; /* Adjusted size */
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.ruling-panel h6 {
    margin-bottom: 10px;
    line-height: 1.1;
    color: var(--text-tertiary);
    font-size: 12px; /* Smaller size for minor headings/notes */
    font-weight: 300;
    letter-spacing: 0.05em;
}

/* --- Specific Styles Based on pt- Classes --- */

/* Ruling Sub-Title (e.g., "POSTANOWIENIE") */
.ruling-panel .pt-Dokumenttytulorzeczenia18 {
    display: block; /* Make span behave like a block */
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 1em;
    text-transform: uppercase;
    color: var(--text-tertiary);
}

/* Basic Document Info (e.g., Date) */
.ruling-panel .pt-Dokumnetpodstawowy {
    display: block;
    text-align: center;
    font-size: 14px;
    margin-bottom: 0.5em;
    color: var(--text-tertiary);
}

/* Main Paragraph/Body Text - Ensure consistency */
.ruling-panel .pt-Domylnaczcionkaakapitu,
.ruling-panel .pt-Domylnaczcionkaakapitu-000005, /* Target common variant */
.ruling-panel .pt-Domylnaczcionkaakapitu-000007,
.ruling-panel .pt-Domylnaczcionkaakapitu-000016 {
    /* Usually inherits from parent <p>, but ensures font if needed */
    font-family: 'Lato', sans-serif;
    color: var(--text-tertiary); /* Ensure color consistency within spans */
}

/* Judge Composition / Signatures / Markers like [SOP] */
.ruling-panel .pt-Dokumentskladsedziowski {
    display: block;
    text-align: center;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    font-size: 14px;
    color: var(--text-tertiary);
    /* Consider `white-space: pre-wrap;` if spacing between names is important and uses multiple spaces */
}

/* Section Heading (e.g., "UZASADNIENIE") - Small Caps */
.ruling-panel .pt-Dokumentpodstawowykapitaliki {
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    font-variant: small-caps; /* Key style for this class */
    margin-top: 2em;
    margin-bottom: 1.5em;
    color: var(--text-tertiary);
}

/* Italicized Text (e.g., Latin phrases) */
.ruling-panel .pt-Domylnaczcionkaakapitu-000013 {
    font-style: italic;
}

/* Superscript Styling */
.ruling-panel sup {
    font-size: 75%; /* Standard relative size */
    line-height: 0; /* Prevent affecting line height */
    position: relative; /* Allows vertical positioning */
    vertical-align: baseline; /* Align based on the line */
    top: -0.5em; /* Move it up */
}

/* Basic Link Styling (for the <a> tags, possibly with class pt-000012) */
.ruling-panel a,
.ruling-panel .pt-000012 { /* Style both generic 'a' and the specific class if used on 'a' */
    color: var(--link-color, #007bff); /* Use a variable or default link color */
    text-decoration: none; /* Optional: remove underline */
}
.ruling-panel a:hover,
.ruling-panel .pt-000012:hover {
    text-decoration: underline; /* Add underline on hover */
}

/* Handle Separator Spans - Often empty, might not need specific style */
/* If they cause unwanted space, you might need display: none; but be careful */
/* .ruling-panel .pt-Separator { */
    /* display: none; */ /* Use cautiously */
/* } */

/* Handle Spans used only for line breaks - they might have numbers */
/* These often just contain <br>, styling the span itself is usually not needed */
.ruling-panel .pt-Domylnaczcionkaakapitu-000003,
.ruling-panel .pt-Domylnaczcionkaakapitu-000008,
.ruling-panel .pt-Domylnaczcionkaakapitu-000017 {
    /* No specific style needed usually, as the <br> inside does the work */
}