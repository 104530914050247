.container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 10px;
  overflow: hidden;
  //   background: linear-gradient(
  //     to right,
  //     #915ac2 0%,
  //     #915ac2 20%,
  //     #7a4baf 40%,
  //     #5a3baa 60%,
  //     #3a2ba5 80%,
  //     blue 100%
  //   );
}

.container2 {
  width: 100%;
  background: linear-gradient(
    to right,
    #915ac2 0%,
    #915ac2 20%,
    #7a4baf 40%,
    #5a3baa 60%,
    #3a2ba5 80%,
    blue 100%
  );
  margin-top: 0;
  position: relative;
}

.svgImg {
  display: block;
  position: relative;
  width: 100%;
}

.contentParent {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
