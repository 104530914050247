@import 'styles/_variables.scss';

.split-pane-col {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.split-pane-row {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.split-pane-top,
.split-pane-bottom,
.split-pane-right,
.split-pane-left {
  flex: 1;
  overflow: hidden;
}

.split-pane-right {
  background-color: #fff;
  background-size: 100% 1.2em;
}

// .split-pane-top,
// .split-pane-bottom {
//   background-color: #ccc;
//   text-align: left;
//   padding: 2%;
// }

.separator-col {
  width: 8px;
  position: relative;
  background: linear-gradient(
    to right,
    var(--background-color-primary) 40%,
    #ccc 40%,
    #ccc 40%,
    var(--background-color-secondary) 40%
  );
  cursor: col-resize;
}

.separator-row {
  border: 5px solid black;
  cursor: row-resize;
}

// ul {
//   margin-left: -2%;
// }

// ul li {
//   margin: 1rem;
//   list-style-type: square;
// }

// ul a {
//   text-decoration: none;
//   color: darkblue;
//   font-size: large;
// }

// ul a:hover {
//   color: black;
// }

// blockquote {
//   border-left: 10px solid #ccc;
//   margin: 5.5em 0 0 0;
//   quotes: "\201C""\201D""\2018""\2019";
//   padding: 2rem 0 1rem;
// }

// blockquote:before {
//   color: #ccc;
//   content: open-quote;
//   font-size: 4em;
//   line-height: 0.1em;
//   margin-right: 0.25em;
//   vertical-align: -0.4em;
// }

// blockquote p {
//   display: inline;
// }

// .quote {
//   max-width: 60%;
//   text-align: right;
//   margin: auto;
//   font-style: italic;
// }

// .quote span {
//   font-weight: bold;
// }
