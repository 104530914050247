$primary-color: #915ac2;

:root {
  --background-color-primary: #ffffff;
  --background-color-secondary: #f3f4f5;
  --background-start-primary: #ffffff;
  --background-start-secondary: #f3f4f5;
  --text-primary: #212121;
  --text-secondary: #757575;
  --text-tertiary: #484848;
  --text-disabled: #bdbdbd;
  --textfield-border: #212121;
  --textfield-border-focused: #3f51b5;
  --medium-gray: #e0e0e0;
  --hover-color: #eeeeee;
  --navbar-color: #060019;
  --background-document: white;
  --highlighted-text-background: #d6ebf2;
  --highlighted-text-background-hover: #b8dae8; /* Slightly darker version for hover effect */
  --document-highlight-background: #90ee90; /* Light green for document highlight */
  --placeholder-color: #3126ff; /* Darker blue color for placeholders */
  --tab-color: #b6acd6;
  --panel-navigaton: lightGray;
  --button-color: #060019;
  --popup-background: 'white';
  --primary: #915ac2;
  --secondary: #060019;
  --button-color-secondary: #915ac2;
  --document-preview-background: #ffffff;
  --pure-paper: #ffffff; /* Pure white paper for light mode */
  --shadow-color: rgba(0, 0, 0, 0.05); /* Shadow color for paper effects */
  --highlight-gradient: linear-gradient(
    135deg,
    rgba(180, 220, 255, 0.5),
    rgba(200, 180, 255, 0.5)
  );
  --highlight-anchor-gradient: linear-gradient(
    135deg,
    rgba(180, 220, 255, 0.6),
    rgba(200, 180, 255, 0.6)
  );
  --highlight-hover-gradient: linear-gradient(
    135deg,
    rgba(180, 220, 255, 0.75),
    rgba(200, 180, 255, 0.75)
  );
}

[data-theme='dark-pl'] {
  --background-color-primary: #060018;
  --background-color-secondary: #121212;
  --background-start-primary: #060018;
  --background-start-secondary: #424242;
  --text-primary: #ccc;
  --text-secondary: #ccc;
  --text-tertiary: #ccc;
  --text-disabled: #686868;
  --textfield-border: #ccc;
  --textfield-border-focused: #ddd;
  --medium-gray: #404040;
  --hover-color: #353535;
  --navbar-color: #353535;
  --background-document: #424242;
  --highlighted-text-background: #095169;
  --highlighted-text-background-hover: #0a6280; /* Slightly lighter version for hover effect */
  --document-highlight-background: #006400; /* Dark green for document highlight */
  --placeholder-color: #64b5f6; /* Lighter blue for placeholders in dark mode */
  --tab-color: #6b55b3;
  --panel-navigaton: #222222;
  --button-color: #6b55b3;
  --popup-background: #353535;
  --primary: #915ac2;
  --secondary: #060019;
  --button-color-secondary: #915ac2;
  --document-preview-background: #000000;
  --pure-paper: #000000; /* Pure black paper for dark mode */
  --shadow-color: rgba(
    255,
    255,
    255,
    0.05
  ); /* Shadow color for paper effects (dark mode) */
  --highlight-gradient: linear-gradient(
    135deg,
    rgba(100, 140, 180, 0.5),
    rgba(120, 100, 180, 0.5)
  );
  --highlight-anchor-gradient: linear-gradient(
    135deg,
    rgba(100, 140, 180, 0.6),
    rgba(120, 100, 180, 0.6)
  );
  --highlight-hover-gradient: linear-gradient(
    135deg,
    rgba(100, 140, 180, 0.75),
    rgba(120, 100, 180, 0.75)
  );
}

[data-theme='light-fr'] {
  --background-color-primary: white;
  --background-color-secondary: #f3f4f5;
  --background-start-primary: white;
  --background-start-secondary: #f3f4f5;
  --text-primary: #212121;
  --text-secondary: #757575;
  --text-tertiary: #484848;
  --text-disabled: #bdbdbd;
  --textfield-border: #212121;
  --textfield-border-focused: #3f51b5;
  --medium-gray: #e0e0e0;
  --hover-color: #eeeeee;
  --navbar-color: #060019;
  --background-document: white;
  --highlighted-text-background: #d6ebf2;
  --highlighted-text-background-hover: #b8dae8; /* Slightly darker version for hover effect */
  --document-highlight-background: #90ee90; /* Light green for document highlight */
  --placeholder-color: #0d47a1; /* Darker blue color for placeholders */
  --tab-color: #b6acd6;
  --panel-navigaton: lightGray;
  --button-color: #005b2f;
  --popup-background: 'white';
  --primary: #005b2f;
  --secondary: #38b6ff;
  --button-color-secondary: #005b2f;
  --document-preview-background: #ffffff;
  --pure-paper: #ffffff; /* Pure white paper for light mode */
  --shadow-color: rgba(0, 0, 0, 0.05); /* Shadow color for paper effects */
  --highlight-gradient: linear-gradient(
    135deg,
    rgba(180, 220, 255, 0.5),
    rgba(200, 180, 255, 0.5)
  );
  --highlight-anchor-gradient: linear-gradient(
    135deg,
    rgba(180, 220, 255, 0.6),
    rgba(200, 180, 255, 0.6)
  );
  --highlight-hover-gradient: linear-gradient(
    135deg,
    rgba(180, 220, 255, 0.75),
    rgba(200, 180, 255, 0.75)
  );
}

[data-theme='dark-fr'] {
  --background-color-primary: #060018;
  --background-color-secondary: #121212;
  --background-start-primary: #060018;
  --background-start-secondary: #424242;
  --text-primary: #ccc;
  --text-secondary: #ccc;
  --text-tertiary: #ccc;
  --text-disabled: #686868;
  --textfield-border: #ccc;
  --textfield-border-focused: #ddd;
  --medium-gray: #404040;
  --hover-color: #353535;
  --navbar-color: #353535;
  --background-document: #424242;
  --highlighted-text-background: #095169;
  --highlighted-text-background-hover: #0a6280; /* Slightly lighter version for hover effect */
  --document-highlight-background: #006400; /* Dark green for document highlight */
  --placeholder-color: #64b5f6; /* Lighter blue for placeholders in dark mode */
  --tab-color: #6b55b3;
  --panel-navigaton: #222222;
  --button-color: #005b2f;
  --popup-background: #353535;
  --primary: #005b2f;
  --secondary: #38b6ff;
  --button-color-secondary: #005b2f;
  --document-preview-background: #000000;
  --pure-paper: #000000; /* Pure black paper for dark mode */
  --shadow-color: rgba(
    255,
    255,
    255,
    0.05
  ); /* Shadow color for paper effects (dark mode) */
  --highlight-gradient: linear-gradient(
    135deg,
    rgba(100, 140, 180, 0.5),
    rgba(120, 100, 180, 0.5)
  );
  --highlight-anchor-gradient: linear-gradient(
    135deg,
    rgba(100, 140, 180, 0.6),
    rgba(120, 100, 180, 0.6)
  );
  --highlight-hover-gradient: linear-gradient(
    135deg,
    rgba(100, 140, 180, 0.75),
    rgba(120, 100, 180, 0.75)
  );
}
