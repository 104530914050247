.newsletterBox {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  border: 1px solid var(--medium-gray);
  max-width: 800px;

  p {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
  }

  .question {
    font-weight: bold;
    margin-top: 15px;
  }
}

.hidden {
  display: none;
}
