@import 'styles/_variables.scss';

.mainDiv {
  background-color: var(--background-color-secondary);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid darkgray;
  max-width: 600px;
  width: 100%;
  position: relative;
}

.headerContainer {
  padding: 0 10px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.settingsTitle {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 15px;
}

.gridCell {
  display: flex;
  align-items: center;
}
