@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'styles/_variables.scss';
 

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');


::-webkit-scrollbar {
  width: 6px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}


html{scroll-behavior:smooth}

body {
  /* scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1; */
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.hero {
  background: url(./assets/images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}

.footer {
  background: url(./assets/images/footer.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) fixed center center;
}


:root {
  --main-bg-color-light: var(--background-color-primary);
  --main-bg-color-dark: var(--background-color-primary);
}

@media (prefers-color-scheme: light) {
  :root {
    --main-bg-color: var(--main-bg-color-light);
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --main-bg-color: var(--main-bg-color-dark);
  }
}