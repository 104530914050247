@import 'styles/_variables.scss';

.documentItem {
  cursor: pointer;
  margin-bottom: 20px;
  position: relative;
  max-width: 100%;
}

.garbageDocumentItem {
  margin-bottom: 20px;
  position: relative;
  max-width: 100%;
}

.documentItemContent {
  flex: 1;
  padding: 15px 20px;
  background-color: var(--background-color-secondary); /* Match the dark gray header color */
  border-radius: 2px;
  position: relative;
  box-shadow: none; /* Removed shadow from outer rectangle */
  min-height: 180px; /* Make rectangles even bigger for better space */
  overflow: hidden;
}

.documentHeader {
  display: flex;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--medium-gray);
  padding-bottom: 8px;
}

.documentDate {
  font-size: 12px;
  margin: 0;
  color: var(--text-primary);
  flex-grow: 1;
}

.documentHour {
  font-weight: 700;
}

.previewWrapper {
  position: relative;
  margin-top: 20px;
  height: 130px; /* Increased height for better growth space */
  overflow: hidden;
}

.documentPreview {
  font-family: 'Arial', sans-serif;
  font-size: 13px;
  line-height: 1.5;
  white-space: pre-wrap;
  overflow: hidden;
  background-color: var(--pure-paper); /* Pure white in light mode, pure black in dark mode */
  padding: 10px 16px;
  margin: 0;
  height: 90px; /* Starting height */
  color: var(--text-primary);
  border-left: 3px solid var(--medium-gray);
  text-align: justify;
  position: absolute; /* Position absolutely to allow independent growth */
  bottom: 0; /* Anchored to the bottom */
  left: 0;
  right: 0;
  transition: height 0.3s ease;
  
  /* Enhanced paper effect with stronger shadow */
  box-shadow: 
    0 2px 5px rgba(0, 0, 0, 0.15),
    0 5px 10px rgba(0, 0, 0, 0.08);
  transform: perspective(1500px) rotateX(1deg);
  transform-origin: bottom center;
  
  /* Subtle paper texture - neutral gradient that works in both modes */
  background-image: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(128, 128, 128, 0.05) 50%,
    transparent 100%
  );
}

/* Horizontal line at bottom of paper that's slightly wider */
.documentPreview::before {
  content: '';
  position: absolute;
  bottom: -1px; /* Position it at the bottom edge */
  left: -30px; /* Extend 30px to the left (was -10px) */
  right: -30px; /* Extend 30px to the right (was -10px) */
  height: 2px; /* Height of the line */
  background-color: var(--medium-gray); /* Use medium gray for the line */
  z-index: 1;
  pointer-events: none;
}

/* Paper fold corner effect - now in top right corner */
.documentPreview::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background: var(--shadow-color);
  z-index: 3;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  pointer-events: none;
}

/* Side bend effect */
.sideBend {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 40px;
  background: linear-gradient(to left, var(--shadow-color), transparent);
  z-index: 2;
  pointer-events: none;
}

/* Hover effect - grow paper from bottom instead of moving it */
.documentItem:hover .documentPreview,
.garbageDocumentItem:hover .documentPreview {
  height: 130px; /* Grow taller to reveal more content */
}
