@import 'styles/_variables.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chatContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.infoContainer {
  width: 100%;
  display: flex;
  justify-content: center;

  .infoTextContainer {
    padding: 16px;
    border: 2px solid var(--primary);
    border-radius: 10px;
  }
}
