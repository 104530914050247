
.highlight-overlay,
.highlight-overlay-anchor {
  background: var(--highlight-gradient); /* Or use a solid color if preferred */
  border-radius: 3px; /* Note: This will apply to corners on EACH line fragment */
  padding: 0.1em 0.2em; /* Add some vertical and horizontal padding */
  margin: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow will also clone */
  transition: background 0.2s ease;
  animation: highlightFadeIn 2.0s ease;

  /* --- Key changes --- */
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone; /* For Safari compatibility */
  display: inline; /* Keep it flowing with text */
  /* Remove white-space: nowrap; */
  /* white-space: nowrap; */ /* REMOVE or comment out this line */
  /* --- End Key changes --- */
}

.highlight-overlay:hover,
.highlight-overlay-anchor:hover {
  background: var(--highlight-hover-gradient);
}

/* Optional: Add line-height to the parent paragraph for better spacing */
.parent-text-container { /* Apply to the <p> or div containing the text */
  line-height: 1.6; /* Adjust as needed */
}



@keyframes highlightFadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Rest of your CSS */
.mainDiv {
  background-color: var(--background-color-secondary);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid darkgray;
  max-width: 600px;
  width: 100%;
  position: relative;
}

.headerContainer {
  padding: 0 10px;
}

.closeButton {
  color: var(--text-primary);
  position: absolute;
  top: 5px;
  right: 5px;
}

.settingsTitle {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 15px;
}

.gridCell {
  display: flex;
  align-items: center;
}